// @flow
import { type JSSThemeDefaultOutcomesType } from '../JSSThemeDefaultOutcomes';

export const JSSThemeOutcomes60900: JSSThemeDefaultOutcomesType = themeVars => {
  const {
    borderRadius,
    margins,
    colors: {
      colorTypes: { primary, white, background1, background2, black, text4 },
    },
  } = themeVars;
  return {
    MobileOutcome: {
      root: {
        width: 50,
        background: primary,
        color: black,
        '&.active': {
          background: text4,
          color: white,
        },
        '&.suspended': {
          opacity: 0.4,
        },
      },
      priceValue: {
        fontSize: 10,
        color: black,
      },
    },
    Outcome: {
      value1x2: {
        opacity: 0.8,
      },
      root: {
        borderRadius: borderRadius.md,
        background: primary,
        color: black,
        '&.active': {
          background: text4,
        },
        '@media (pointer: coarse)': {
          height: 35,
          lineHeight: '35px',
          padding: [0, margins.md],
        },
        '@media not all and (pointer: coarse)': {
          '&:hover': {
            background: '#171b28',
          },
        },
        '&.disabled': {
          background: background1,
          color: background2,
          opacity: '.5',
        },
      },
    },
  };
};

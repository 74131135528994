// @flow
import { LoadableComponentWrapper } from '../../components/components/LoadableComponentWrapper';
import { createKoltronApp } from '../createKoltronApp';
import {
  JSSTheme60900,
  JSSThemeVariables60900,
  getJSSThemePatterns60900,
} from './JSSTheme60900';
import { GeoblockPage60900 } from './GeoblockPage60900';
// $FlowIgnore
import '../../../styles/application-koltron.scss';

createKoltronApp({
  DesktopComponent: LoadableComponentWrapper({
    loader: () =>
      import('./FullDesktop60900').then(module => module.FullDesktop60900),
  }),
  MobileComponent: LoadableComponentWrapper({
    loader: () =>
      import('./FullMobile60900').then(module => module.FullMobile60900),
  }),
  JSSTheme: JSSTheme60900,
  JSSThemeVariables: JSSThemeVariables60900,
  JSSThemePatterns: getJSSThemePatterns60900,
  GeoblockPage: GeoblockPage60900,
});

// @flow
import { type JSSThemeDefaultAffiliatesType } from '../JSSThemeDefaultAffiliates';

export const JSSThemeAffiliates60900: JSSThemeDefaultAffiliatesType = themeVars => {
  const {
    margins,
    colors: {
      colorTypes: { text2, text4, accent, white },
    },
  } = themeVars;

  return {
    text: {
      margin: margins.md,
    },
    affiliatesHeading: {
      background: text4,
      margin: '0 auto',
      padding: '100px 20px 50px',
    },
    affiliatesHome: {
      topBanner: {
        background: text4,
      },
      topBannerHeader: {
        '@media (pointer: coarse)': {
          color: white,
        },
      },
      stepDescription: {
        opacity: '1',
      },
      topBannerDescription: {
        '@media (pointer: coarse)': {
          color: white,
        },
      },
      contentHeading: {
        padding: '80px 0 60px',
      },
      reasonsItemRoot: {
        paddingBottom: 40,
      },
      reasonsItem: {
        color: text4,
      },
      affiliateRoot: {
        background: text4,
      },
      productsRoot: {
        background: text4,
      },
      productsImg: {
        padding: '80px 70px 30px 0',
        alignSelf: 'flex-start',
      },
      productsHeading: {
        padding: '80px 0 50px',
        fontWeight: '800 !important',
      },
      topBannerButton: {
        background: '#d8a549',
        backgroundColor: '#d8a549',
        '&:hover': {
          color: '#fff',
        },
      },
      productsButton: {
        background: '#d8a549',
        backgroundColor: '#d8a549',
        '&:hover': {
          color: '#fff',
        },
      },
      topBannerRoot: {
        background: text4,
      },
    },
    affiliatesProducts: {
      productsButton: {
        background: '#d8a549',
        backgroundColor: '#d8a549',
        '&:hover': {
          color: '#fff',
        },
      },
      productsText: {
        maxWidth: '100%',
      },
    },
    affiliatesFAQ: {
      FAQText: {
        maxWidth: '100%',
      },
      FAQquestion: {
        maxWidth: '100%',
      },
    },
    affiliatesCommission: {
      commissionText: {
        maxWidth: '100%',
      },
    },
    affiliatesContacts: {
      contactsMail: {
        '&:hover': {
          color: accent,
        },
      },
      contactsText: {
        maxWidth: '100%',
      },
    },
    affiliateHeader: {
      headerRoot: {
        background: text4,
      },
      burgerOverlay: {
        background: text4,
      },
      topMenuItem: {
        color: '#fff !important',
        '&:hover': {
          color: `${accent} !important`,
        },
      },
      topMenuItemActive: {
        color: `${accent} !important`,
      },
      loginBarRoot: {
        background: text2,
        '@media (pointer: coarse)': {
          background: text4,
        },
      },
      SignupRoot: {
        color: '#fff',
        '&:hover': {
          color: '#fff',
          opacity: '.6',
        },
      },
      reasonsIcon: {
        fill: accent,
      },
    },
    affiliatesFooter: {
      productsTerms: {
        color: '#fff',
        '&:hover': {
          color: accent,
        },
      },
      footer: {
        background: text4,
        opacity: 1,
      },
      footerRoot: {
        background: text4,
      },
    },
  };
};

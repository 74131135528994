// @flow
import * as React from 'react';
import { Modal } from './Modal';
import { CloseIcon } from './CloseIcon';
import cx from 'classnames';
import { makeBemClass } from '../../utils';

const bem = makeBemClass('osg-modal');

type ButtonType = {
  id: string,
  action: (any) => void,
  title: string,
  type?: string,
};

export const ModalBox = ({
  visible,
  className,
  close,
  title,
  buttons,
  children,
  classNameModificator,
  gaProps,
}: {
  visible: boolean,
  children?: React.Node,
  className?: string,
  close?: (void) => any,
  title?: React.Node,
  buttons?: Array<ButtonType>,
  classNameModificator?: string,
  gaProps?: Object,
}) => {
  if (!visible) return null;

  const bemWithModificator = (el) => bem(el, classNameModificator);

  return (
    <Modal className={cx(className)}>
      <div className={bemWithModificator('background')} onClick={close} />
      <div className={bemWithModificator('content')} {...gaProps}>
        {close && (
          <div className={bemWithModificator('content-close-wrap')}>
            <CloseIcon onClick={close} />
          </div>
        )}
        {title && (
          <div className={bemWithModificator('header')}>
            <h4 className={bemWithModificator('header-title')}>{title}</h4>
          </div>
        )}
        <div className={bemWithModificator('body')}>
          <div className={bemWithModificator('body-text')}>{children}</div>
        </div>
        {buttons && (
          <div className={bemWithModificator('footer')}>
            {buttons.map(({ id, action, title: btnTitle, type }) => (
              <button
                type={type}
                key={btnTitle}
                onClick={action}
                className="osg-btn osg-btn--modal"
                id={id}
              >
                {btnTitle}
              </button>
            ))}
          </div>
        )}
      </div>
    </Modal>
  );
};

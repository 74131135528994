// @flow
import { type JSSThemeDefaultSportsMenuType } from '../JSSThemeDefaultSportsMenu';
// import { messageHandlerSettings } from '../../diffusion/handlers/message';

export const JSSThemeSportsMenu60900: JSSThemeDefaultSportsMenuType = themeVars => {
  const {
    fontSizes,
    margins,
    colors: {
      colorTypes: { primary, white, background3, text5, text4, black, inform },
      colorCombinations: { whitePrimary },
    },
  } = themeVars;

  return {
    SportsMenuDesktop: {
      header: {
        color: white,
      },
      sport: {
        background: text5,
        color: white,
        '&.active, &:hover': {
          opacity: 1,
        },
      },
      region: {
        background: text4,
        color: white,
      },
    },
    HorizontalSportsTabs: {
      rootSmallIcons: {
        margin: 0,
        height: 30,
      },
    },
    HorizontalSportsListUISmallIcons: {
      item: {
        color: primary,
        fontWeight: 'bold',
      },
      sportLink: {
        opacity: 1,
        '&.active, &:hover': {
          color: black,
          opacity: 1,
        },
      },
      list: {
        paddingTop: margins.xs,
      },
    },
    SubMenuListUIMobile: {
      link: {
        color: white,
        '&:hover,&:focus': {
          color: white,
        },
        '&.active': {
          ...whitePrimary,
        },
      },
    },
    HorizontalSportsListIconBig: {
      sportIcon: {
        color: white,
        '&.active': {
          color: primary,
        },
      },
      sportName: {
        color: white,
        '&.active': {
          color: primary,
        },
      },
    },
    HorizontalSportsListIconSmall: {
      sportIcon: {
        fontSize: fontSizes.md,
      },
      sportName: {
        fontSize: fontSizes.md,
      },
    },
    HorizontalLiveCasinoIcon: {
      icon: {
        background: white,
        color: inform,
      },
      title: {
        color: white,
      },
    },
    HorizontalSportsListUIBigIcons: {
      list: {
        background: background3,
      },

      sportLink: {
        color: white,
      },
    },
  };
};

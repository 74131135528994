// @flow
import { type JSSThemeDefaultBetslipType } from '../JSSThemeDefaultBetslip';

export const JSSThemeBetslip60900: JSSThemeDefaultBetslipType = (themeVars) => {
  const {
    fontSizes,
    margins,
    colors: {
      colorTypes: {
        inform,
        white,
        background2,
        text4,
        primary,
        black,
        background5,
      },
    },
  } = themeVars;
  return {
    Betslip: {
      root: {
        background: white,
      },
      wrapTotals: {
        background: white,
      },
      info: {
        margin: 0,
        padding: [0, margins.md, margins.xm],
      },
      message: {
        background: background5,
      },
    },
    Receipt: {
      titleContainer: {
        background: primary,
        color: black,
      },
    },
    BetslipTotals: {
      summaryLabel: {
        color: text4,
        paddingTop: margins.md,
        fontWeight: 'bold',
      },
      summaryValue: {
        color: text4,
      },
    },
    BetslipSelection: {
      common: {
        background: background2,
      },
    },
    MyBetsTab: {
      tab: {
        boxShadow: 'inset 0 5px 4px rgba(0, 0, 0, 0.06)',
        background: `#ebebeb !important`,
        '&.active, &:hover': {
          boxShadow: `none !important`,
          background: `${white} !important`,
        },
      },
    },
    Delta: {
      deltaIncreased: {
        background: `${inform} !important`,
        fontSize: fontSizes.sm,
      },
      deltaDecreased: {
        fontSize: fontSizes.sm,
        background: `${inform} !important`,
      },
    },
    BadgeAmount: {
      badgeAmount: {
        background: `#F8A939 !important`,
      },
    },
    ShareBets: {
      message: {
        '&.shown': {
          color: inform,
        },
      },
    },
  };
};

import React from 'react';
import { type JSSThemeType } from '../origins/JSSThemeDefault';
import { useJSSStyles } from '../jss-hooks';
import { replaceTags } from '../koltron-utils/helpers';

const pathArticleBodyDefault = (theme: JSSThemeType) =>
  theme.components.article.articelBody;
const DEFAULT_SEO_TEXT = '<b>Insert SEO text here...</b>';

const PostDebouncer = ({ data, type }) => {
  const classes = useJSSStyles(pathArticleBodyDefault);
  if (data.text === DEFAULT_SEO_TEXT) {
    return <></>;
  }

  const preparedText = __OSG_CONFIG__.seoBTagChange ? replaceTags(['b', 'strong'], data.text) : data.text;

  if (type === 'header') {
    const HLevelTag = `h${data.level}`;

    return (
      <HLevelTag
        className={classes[`articleH${data.level}`]}
        dangerouslySetInnerHTML={{ __html: preparedText }}
      />
    );
  }

  return (
    <>
      <p
        className={classes.articleP}
        dangerouslySetInnerHTML={{ __html: preparedText }}
      />
    </>
  );
};

export default PostDebouncer;

// @flow
import { type JSSThemeDefaultHeaderType } from '../JSSThemeDefaultHeader';

export const JSSThemeHeader60900: JSSThemeDefaultHeaderType = themeVars => {
  const {
    margins,
    colors: {
      colorTypes: { text4 },
    },
  } = themeVars;
  return {
    HeaderDesktop: {
      root: {
        background: text4,
      },
      extraUserElementsWrapper: {
        margin: [0, 0, margins.md, 0],
      },
    },
    LogoBrand: {
      logoWrapper: {
        width: 250,
        padding: margins.xm,
      },
      logo: {
        width: '100%',
      },
    },
  };
};

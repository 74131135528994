// @flow
import { JSSThemeVariables } from '../JSSThemeDefault';

export const JSSThemeArticleBody60900 = (
  themeVars: typeof JSSThemeVariables
) => {
  const {
    colors: {
      colorTypes: { black, white },
    },
  } = themeVars;
  return {
    articelBody: {
      articelWrap: {
        color: black,
        '@media (pointer: coarse)': {
          color: white,
          paddingTop: 10,
        },
      },
    },
  };
};

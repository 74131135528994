// @flow
import { dissoc } from 'ramda';
import {
  BETSLIP_STATUSES,
  acceptChangesHandler,
  getIsSelectionSingle,
  getIsSelectionOpen,
  resetSelection,
  handleSelectionsPayload,
} from '../selectors/betslip-utils';
import {
  BETSLIP_SET_SELECTIONS,
  BETSLIP_CLEAR_SELECTIONS,
  BETSLIP_ACCEPT_CHANGES,
  BETSLIP_COPY_STAKE,
  BETSLIP_UPDATE_SELECTION,
  BETSLIP_RECEIPT_RETAIN_SELECTIONS,
  BETSLIP_SET_RECEIPT,
  BETSLIP_RECEIPT_CONTINUE,
  BETSLIP_SET_ERROR,
  BETSLIP_START_PLACE_BET,
  BETSLIP_TOGGLE_SELECTION,
} from '../actions/betslip';
import { BETSLIP_CONNECTION_ERROR } from '../../../common/constants';
import { type BetslipType, type ReduxAction } from '../../types';

const betslipDefaultState = {
  status: BETSLIP_STATUSES.betslip,
  errors: [],
  selections: {},
  outcomeIds: [],
  receipt: [],
  selectionsInitialized: false,
};

export const betslipReducer = (
  state: BetslipType = betslipDefaultState,
  action: ReduxAction
) => {
  switch (action.type) {
    /**
     * This action is returned usually when new selections should be set.
     * But it can also be returned from epic betslipSetInitialSelectionsEpic
     * In that case we populate fresh empty state of just created app with selections
     * from ?selections= param from url
     */
    case BETSLIP_SET_SELECTIONS: {
      /**
       * Prevent initializing from url more than once during session
       * If we have initialized once and new initial data incomes then state is not mutated
       * */
      if (state.selectionsInitialized && !!action.payload?.initial)
        return state;

      let errors = [];
      if (action.payload.error) {
        errors.push(action.payload.error);
      } else {
        const stateErrors: Array<string> = state.errors.filter(
          (e) => e !== BETSLIP_CONNECTION_ERROR
        );
        errors.push(...stateErrors);
      }

      const handledPayload = handleSelectionsPayload(
        state,
        action.payload.selections,
        action.payload.manual,
        !!action.payload.initial
      );

      return {
        ...state,
        ...handledPayload,
        errors,
        selectionsInitialized:
          state.selectionsInitialized ||
          (!!action.payload.initial && handledPayload.outcomeIds.length > 0),
      };
    }
    case BETSLIP_CLEAR_SELECTIONS: {
      return {
        ...state,
        selections: betslipDefaultState.selections,
        outcomeIds: betslipDefaultState.outcomeIds,
      };
    }
    case BETSLIP_ACCEPT_CHANGES: {
      const nextSelections = acceptChangesHandler(state.selections);
      const nextOutcomeIds: typeof state.outcomeIds = state.outcomeIds.filter(
        (outcomeId) => nextSelections[outcomeId.id]
      );

      return {
        ...state,
        selections: nextSelections,
        outcomeIds: nextOutcomeIds,
      };
    }
    case BETSLIP_COPY_STAKE: {
      const nextSelections = {};
      const selectionKeys = Object.keys(state.selections);

      for (const key of selectionKeys) {
        const selection = state.selections[key];
        if (getIsSelectionSingle(selection) && getIsSelectionOpen(selection)) {
          nextSelections[key] = {
            ...selection,
            stake: action.payload,
          };
        } else {
          nextSelections[key] = selection;
        }
      }

      return {
        ...state,
        selections: nextSelections,
      };
    }
    case BETSLIP_UPDATE_SELECTION: {
      const { id, data } = action.payload;
      const selection = state.selections[id];
      if (!selection) return state;

      return {
        ...state,
        selections: {
          ...state.selections,
          [id]: {
            ...selection,
            ...data,
          },
        },
        errors: betslipDefaultState.errors,
      };
    }
    case BETSLIP_RECEIPT_RETAIN_SELECTIONS: {
      const nextSelections = {};
      const selectionKeys = Object.keys(state.selections);
      for (const key of selectionKeys) {
        nextSelections[key] = resetSelection(state.selections[key]);
      }

      return {
        ...state,
        selections: nextSelections,
        status: BETSLIP_STATUSES.betslip,
        receipt: betslipDefaultState.receipt,
      };
    }
    case BETSLIP_SET_RECEIPT: {
      return {
        ...state,
        status: BETSLIP_STATUSES.receipt,
        receipt: action.payload,
      };
    }
    case BETSLIP_RECEIPT_CONTINUE: {
      return betslipDefaultState;
    }
    case BETSLIP_SET_ERROR: {
      return {
        ...state,
        ...(action.payload.selections &&
          handleSelectionsPayload(state, action.payload.selections)),
        errors: action.payload.errors,
        status: BETSLIP_STATUSES.betslip,
      };
    }
    case BETSLIP_START_PLACE_BET: {
      return {
        ...state,
        errors: betslipDefaultState.errors,
        status: BETSLIP_STATUSES.betPlacement,
      };
    }
    case BETSLIP_TOGGLE_SELECTION: {
      const { outcomeIds, selections } = state;

      const nextOutcomeIds: typeof outcomeIds = [];
      let nextSelections: typeof selections = selections;

      if (BETSLIP_STATUSES.betslip === state.status) {
        let isInOutcomeIds = false;
        for (const outcomeId of outcomeIds) {
          if (outcomeId.id === action.payload.id) {
            isInOutcomeIds = true;
            nextSelections = dissoc(action.payload.id, nextSelections);
          } else {
            nextOutcomeIds.push(outcomeId);
          }
        }

        if (!isInOutcomeIds) {
          nextOutcomeIds.push(action.payload);
        }
      } else {
        nextOutcomeIds.push(action.payload);
        nextSelections = betslipDefaultState.selections;
      }

      return {
        ...state,
        outcomeIds: nextOutcomeIds,
        selections: nextSelections,
        errors: action.payload.errors
          ? action.payload.errors
          : betslipDefaultState.errors,
        status: BETSLIP_STATUSES.betslip,
      };
    }
    default:
      return state;
  }
};

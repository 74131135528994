// @flow
import { type JSSThemeDefaultHeaderMenuType } from '../JSSThemeDefaultHeaderMenu';

export const JSSThemeHeaderMenu60900: JSSThemeDefaultHeaderMenuType = themeVars => {
  const {
    margins,
    fontSizes,
    colors: {
      colorTypes: { primary, white, text4 },
    },
  } = themeVars;

  return {
    HeaderMenuDesktop: {
      menuWrapper: {
        background: text4,
      },
      menuItem: {
        padding: [0, margins.xm],
        fontWeight: 'bold',
        fontSize: fontSizes.md,
        color: white,
        '@media screen and (max-width: 1443px)': {
          fontSize: fontSizes.xsm,
        },
        '&:hover, &:focus, &:active': {
          color: primary,
        },
      },
      menuItemActive: {
        color: primary,
      },
      menuItemWrapper: {
        borderRight: 'none',
      },
    },
    HeaderMenuMobile: {
      menuItem: {
        color: white,
        borderBottom: 'none',
        '&:hover, &:focus': {
          color: white,
        },
        '&.active': {
          color: primary,
        },
      },
    },
  };
};

// @flow
import { type JSSThemeVariablesType } from '../JSSThemeDefault';

export const JSSThemeXtremepush60900 = (themeVars: JSSThemeVariablesType) => {
  return {
    xtremepushBadge: {
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      top: 0,
      right: -5,
      height: 20,
      minWidth: 20,
      paddingLeft: 5,
      paddingRight: 5,
      fontSize: 14,
      borderRadius: 10,
      color: '#ff0505',
      backgroundColor: 'white',
      pointerEvents: 'none',
    },
  };
};

// @flow
import { type JSSThemeDefaultFormType } from '../JSSThemeDefaultForm';

export const JSSThemeForm60900: JSSThemeDefaultFormType = themeVars => {
  const {
    colors: {
      colorTypes: { accent, error, white, neutral2, text3, black },
    },
    borderRadius,
    margins,
    fontSizes,
  } = themeVars;
  return {
    FileInput: {
      uploadButton: {
        background: accent,
        color: white,
        '&:hover': {
          background: '#1a27ae',
          color: white,
        },
      },
    },
    Select: {
      selectRoot: {
        color: `${text3} !important`,
        border: `1px solid ${neutral2} !important`,
      },
    },
    Input: {
      root: {
        background: '#fff',
        borderRadius: borderRadius.lg,
        lineHeight: 'auto',
        height: 'auto',
        padding: [10, 10, 10, 14],
        borderColor: neutral2,
      },
      betslipInput: {
        borderRadius: 2,
        color: black,
        background: white,
      },
    },
    Field: {
      root: {
        color: 'black',
        marginBottom: margins.xm * 2,
      },
      fieldLabel: {
        fontSize: fontSizes.lg,
        display: 'block',
        padding: [0, 0, margins.xs],
        fontWeight: 'bold',
      },
      checkboxField: {
        margin: ['30px', 0, 0],
        position: 'relative',
      },
    },
    SubmitSection: {
      submitSectionError: {
        color: `${error} !important`,
      },
    },
  };
};

// @flow
import { type JSSThemeDefaultDropdownMenuType } from '../JSSThemeDefaultDropdownMenu';

export const JSSThemeDropdownMenu60900: JSSThemeDefaultDropdownMenuType = themeVars => {
  const {
    colors: {
      colorTypes: { text4, neutral1, white, background3, inform },
    },
  } = themeVars;
  return {
    dropdownDesktop: {
      item: {
        color: text4,
        background: neutral1,
        '&:hover,&.active': { color: white, background: background3 },
      },
    },

    dropdownMobile: {
      select: {
        color: inform,
      },
    },
  };
};


// @flow
import { type $DeepShape, JSSThemeVariables } from './JSSThemeDefault';

export const JSSThemeDefaultMyBets = (themeVars: typeof JSSThemeVariables) => {
  const {
    margins,
    fontSizes,
    colors: {
      colorCombinations: {
        whitePrimary,
        text1White,
        text2Neutral1,
        whiteInform,
      },
      colorTypes: { error },
    },
    borderRadius,
  } = themeVars;

  return {
    ConfirmationBox: {
      confirmation: {
        overflow: 'hidden',
        fontWeight: 'bold',
        padding: margins.md,
        lineHeight: 1.3,
        fontSize: fontSizes.md,
        borderRadius: borderRadius.md,
        marginTop: margins.xs,
        ...text1White,
      },
      buttonsContainer: {
        display: 'flex',
        margin: [0, -margins.md],
        padding: [margins.md, 0, 0],
        flexFlow: 'row wrap', // move button to the next line for long name
      },
      buttonYes: {
        flex: 1,
        margin: [0, margins.md / 2],
        padding: [margins.md / 2],
      },
      buttonNo: {
        flex: 1,
        margin: [0, margins.md / 2],
        padding: [margins.md / 2],
      },
      btnYes: {
        background: '',
        '&:hover': {
          background: '',
        },
      },
      btnNo: {
        background: '',
        color:'',
        '&:hover': {
          background: '',
        },
      },
      text: {},
      error: {
        color: error,
        paddingTop: margins.md,
      },
      loading: {},
    },
    MyBets: {
      question: {
        opacity: '0.7',
        cursor: 'pointer',
        margin: 0,
        transition: '0.2s ease-in',
        ...whitePrimary,
      },
      content: {
        display: 'flex',
        flexDirection: 'column',
        ...text1White,
        position: 'relative',
        lineHeight: 1.3,
        fontSize: fontSizes.md,
      },
      myBetsTabsItem: {
        cursor: 'pointer',
        ...text2Neutral1,
        flex: 1,
        textAlign: 'center',
        padding: margins.sm,
        fontSize: fontSizes.sm,
        fontWeight: 'bold',
        borderRadius: [borderRadius.lg, 0, 0, borderRadius.lg],
        '&:last-child ': {
          borderRadius: [0, borderRadius.lg, borderRadius.lg, 0],
        },
        '&:hover, &.active': whiteInform,
      },
      myBetsTabsWrapper: {
        display: 'flex',
        padding: margins.md,
      },
      tabsContent: {},
    },
    CashoutButton: {
      root: {
        margin: [margins.xs, 0, 0],
      },
      cashoutButton: {
        background: '',
        '&:hover': {
          background: '',
        },
      },
    },
    CashOut: {
      success: {
        ...text1White,
        fontWeight: 'bold',
        padding: margins.md,
        marginTop: margins.xs,
      },
    },
  };
};

export type JSSThemeDefaultMyBetsType = (
  typeof JSSThemeVariables
) => $DeepShape<$Call<typeof JSSThemeDefaultMyBets, typeof JSSThemeVariables>>;

import { type JSSThemeDefaultAccountSectionType } from '../JSSThemeDefaultAccountSection';

export const JSSThemeAccountSection60900: JSSThemeDefaultAccountSectionType = themeVars => {
  const {
    colors: {
      colorTypes: { background2, black, primary, white, text4 },
    },
    margins,
  } = themeVars;
  return {
    OPMProvider: {
      providerRoot: {
        color: '#000',
      },
    },
    OPMPageComponent: {
      OPMroot: {
        margin: [0, margins.xm],
      },
    },
    PendingWithdrawal: {
      wrapper: {
        padding: [0, margins.xm, margins.xm],
      },
    },
    AccountPageDesktop: {
      accountPageLeftContent: {
        background: text4,
        width: 300,
        paddingTop: margins.md,
      },
      accountPageRightContent: {
        background: background2,
        color: black,
      },
    },
    BetsHistory: {
      filterSelectionItemActive: {
        background: text4,
        color: white,
      },
      filterSelectionItem: {
        background: primary,
        color: black,
        '&:hover': {
          background: text4,
          color: white,
        },
        '@media (pointer: coarse)': {
          marginRight: margins.xs,
        },
      },
      BetsHistoryHeading: {
        color: '#000',
      },
      filtersTitle: {
        color: '#000',
      },
    },
    OPMForms: {
      form: {
        background: background2,
        color: '#000',
      },
    },
  };
};

// @flow
import { type JSSThemeVariablesType, type $DeepShape } from './JSSThemeDefault';

export const JSSThemeDefaultArticleBody = (
  themeVars: JSSThemeVariablesType
) => {
  const {
    margins,
    colors: {
      colorTypes: { primary, accent },
    },
  } = themeVars;
  return {
    articelBody: {
      articelWrap: {
        display: 'flex',
        flexDirection: 'column',
        padding: [0, margins.lg, 0],
        position: 'relative',
        marginBottom: 0,
        maxWidth: '100%',
        margin: '0 auto',
      },
      articleH1: {},
      articleH2: {
        marginTop: margins.xm,
        marginBottom: margins.md,
        fontWeight: 'bold',
        fontSize: 28,
      },
      articleH3: {
        marginTop: margins.md,
        marginBottom: margins.md,
        fontWeight: 'bold',
        fontSize: 22,
      },
      articleH4: {
        marginTop: margins.sm,
        marginBottom: margins.md,
        fontWeight: 'bold',
        fontSize: 18,
      },
      articleP: {
        marginTop: 0,
        maxWidth: '100%',
        '@global': {
          a: {
            color: primary,
            '&:hover': {
              color: accent,
            },
          },
        },
      },
      articleIcon: {
        position: 'absolute',
        bottom: '-5em',
        right: '-5em',
        transform: 'rotate(15deg)',
        opacity: 0.2,
        width: '40em !important',
        height: 'auto',
        maxWidth: '40em',
        maxHeight: '40em',
      },
    },
  };
};

export type JSSThemeDefaultHeaderType = (JSSThemeVariablesType) => $DeepShape<
  $Call<typeof JSSThemeDefaultArticleBody, JSSThemeVariablesType>
>;

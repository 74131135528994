// @flow
import { type JSSThemeDefaultLayoutType } from '../JSSThemeDefaultLayout';

export const JSSThemeLayout60900: JSSThemeDefaultLayoutType = themeVars => {
  const {
    colors: {
      colorTypes: { white, text5 },
    },
  } = themeVars;
  return {
    ThreeColumnLayout: {
      leftColumn: {
        background: `${text5} !important`,
        marginTop: 20,
        borderRadius: '0 3px 0 0',
      },
      centerColumn: {
        boxShadow: 'none',
      },
      rightColumn: {
        background: `${white} !important`,
        marginTop: 20,
        borderRadius: 3,
      },
    },
  };
};

// import { type JSSThemeDefaultFooterType } from '../JSSThemeDefaultFooter';
// @flow
import { type JSSThemeDefaultFooterAllType } from '../JSSThemeDefaultFooterAll';

export const JSSThemeFooterAll60900: JSSThemeDefaultFooterAllType = (
  themeVars
) => {
  const {
    colors: {
      colorTypes: { white, text5 },
    },
  } = themeVars;
  return {
    footerWrap: {
      background: text5,
      color: white,
    },
    licenseLink: {
      paddingRight: 0,
    },
    menuWrapper: {
      paddingBottom: 0,
    },
    headerMenu: {
      fontWeight: 'bold',
      '@media screen and (max-width: 1370px)': {
        fontWeight: 'bold',
      },
    },
  };
};

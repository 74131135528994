// @flow
import { type JSSThemeDefaultMyBetsType } from '../JSSThemeDefaultMyBets';

export const JSSThemeMyBets60900: JSSThemeDefaultMyBetsType = themeVars => {
  const {
    colors: {
      colorTypes: { background2, primary },
    },
  } = themeVars;

  return {
    MyBets: {
      myBetsTabsItem: {
        '&:hover, &.active': {
          background: primary,
        },
      },
      content: {
        background: background2,
      },
    },
  };
};

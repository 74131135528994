// @flow
import * as React from 'react';
import { useLocation } from 'react-router';

import PostDebouncer from './PostDebouncer';
import { type JSSThemeType } from '../origins/JSSThemeDefault';
import { useJSSStyles } from '../jss-hooks';
import {
  useSeoTextData,
  useSeoHeading,
  useSeoHeaderLoaded,
  useReduxDispatch,
} from '../redux-hooks';
import { SEO_BLOCK_UNMOUNT } from '../koltron-redux/actions/action-types';

const pathArticleBodyDefault = (theme: JSSThemeType) =>
  theme.components.article.articelBody;

export const SEODescriptionOnPages = () => {
  const seoTextData = useSeoTextData();
  const seoH1 = useSeoHeading();
  const isHeaderLoaded = useSeoHeaderLoaded();
  const { pathname } = useLocation();
  const dispatch = useReduxDispatch();

  const [isH1onPage, setIsH1onPage] = React.useState(true);

  const classes = useJSSStyles(pathArticleBodyDefault);

  React.useEffect(() => {
    const isHeader = !!document.querySelector('h1');
    /** Finds <h1> on page if <h1> is inside synchronic component */
    if (!isHeader && !isHeaderLoaded) {
      setIsH1onPage(false);
      /** Subscribed on async components witch has <h1> on page */
    } else if (isHeaderLoaded || isHeader) {
      setIsH1onPage(true);
    }
    return () => {
      dispatch({
        type: SEO_BLOCK_UNMOUNT,
        payload: {
          isHeader: false,
        },
      });
    };
  }, [isHeaderLoaded, pathname, dispatch]);

  return (
    <article itemProp="articleBody" className={classes.articelWrap}>
      {seoH1 && !isH1onPage && <h1>{seoH1}</h1>}
      {Array.from(seoTextData || []).map((item) => (
        <PostDebouncer
          key={seoTextData && seoTextData.indexOf(item)}
          data={item.data}
          type={item.type}
        />
      ))}
    </article>
  );
};

// @flow
import {
  type JSSThemeShapeType,
  type JSSThemeVariablesShapeType,
  type ColorTypes,
  getColorCombinations,
  type GetJSSThemePatternsType,
  colorTypes,
  getJSSThemePatterns,
} from '../JSSThemeDefault';
import { JSSThemeHeaderMenu60900 } from './JSSThemeHeaderMenu60900';
import { JSSThemeHeader60900 } from './JSSThemeHeader60900';
import { JSSThemeBetslip60900 } from './JSSThemeBetslip60900';
import { JSSThemeSportsMenu60900 } from './JSSThemeSportsMenu60900';
import { JSSThemeLayout60900 } from './JSSThemeLayout60900';
import { JSSThemeLanguageSwitcher60900 } from './JSSThemeLanguageSwitcher60900';
import { JSSThemeArticleBody60900 } from './JSSThemeArticleBody60900';
import { JSSThemeSearch60900 } from './JSSThemeSearch60900';
import { JSSThemeCoupons60900 } from './JSSThemeCoupons60900';
import { JSSThemeBannerItems60900 } from './JSSThemeBannerItems60900';
import { JSSThemeDefaultWidgets60900 } from './JSSThemeWidgets60900';
import { JSSThemeForm60900 } from './JSSThemeForm60900';
import { JSSThemeAuthForm60900 } from './JSSThemeAuthForm60900';
import { JSSThemeRegistration60900 } from './JSSThemeRegistration60900';
import { JSSThemeBalance60900 } from './JSSThemeBalance60900';
import { JSSThemeMyBets60900 } from './JSSThemeMyBets60900';
import { JSSThemeLive60900 } from './JSSThemeLive60900';
import { JSSThemeCasino60900 } from './JSSThemeCasino60900';
import { JSSThemeHeaderMobile60900 } from './JSSThemeHeaderMobile60900';
import { JSSThemeOutcomes60900 } from './JSSThemeOutcomes60900';
import { JSSThemeAccountSection60900 } from './JSSThemeAccountSection60900';
import { JSSThemePopularLeagues60900 } from './JSSThemePopularLeagues60900';
import { JSSThemeFooter60900 } from './JSSThemeFooter60900';
import { JSSThemeCookies60900 } from './JSSThemeCookies60900';
import { JSSThemeBonuses60900 } from './JSSThemeBonuses60900';
import { JSSThemeTooltip60900 } from './JSSThemeTooltip60900';
import { JSSThemeFooterAll60900 } from './JSSThemeFooterAll60900';
import { JSSThemePromotions60900 } from './JSSThemePromotions60900';
import { JSSThemeSitemap60900 } from './JSSThemeSitemap60900';
import { JSSThemeDropdownMenu60900 } from './JSSThemeDropdownMenu60900';
import { JSSThemeBetgenius60900 } from './JSSThemeBetgenius60900';
import { JSSThemeXtremepush60900 } from './JSSThemeXtremepush60900';
import { JSSThemeAffiliates60900 } from './JSSThemeAffiliates60900';

export const colorTypes60900: ColorTypes = {
  ...colorTypes,
  text2: '#73747d',
  text3: '#333',
  text4: '#171b28',
  text5: '#171b28',
  primary: '#d8a549',
  inform: '#E6331E',
  error: '#E6331E',
  accent: '#d8a549',
  background1: '#303030',
  background2: '#ebebeb',
  background3: '#171b28',
  background4: '#bbb',
  background5: '#989797',
  background6: '#ccc',
  background7: '#ebebeb',
  neutral1: '#ebebeb',
  neutral2: '#c1cad0',
};

export const colorCombinations60900 = getColorCombinations(colorTypes60900);

export const JSSThemeVariables60900: JSSThemeVariablesShapeType = {
  colors: {
    colorCombinations: colorCombinations60900,
    colorTypes: colorTypes60900,
  },
};

export const JSSTheme60900: JSSThemeShapeType = (themeVars) => {
  const {
    margins,
    fontSizes,
    colors: {
      colorTypes: {
        accent,
        white,
        background2,
        background3,
        black,
        background5,
        text5,
        text4,
      },
    },
  } = themeVars;
  return {
    banners: JSSThemeBannerItems60900(themeVars),
    header: JSSThemeHeader60900(themeVars),
    headerMenu: JSSThemeHeaderMenu60900(themeVars),
    betslip: JSSThemeBetslip60900(themeVars),
    sportsMenu: JSSThemeSportsMenu60900(themeVars),
    layout: JSSThemeLayout60900(themeVars),
    languageSwitcher: JSSThemeLanguageSwitcher60900(themeVars),
    article: JSSThemeArticleBody60900(themeVars),
    search: JSSThemeSearch60900(themeVars),
    coupons: JSSThemeCoupons60900(themeVars),
    widgets: JSSThemeDefaultWidgets60900(themeVars),
    form: JSSThemeForm60900(themeVars),
    authForm: JSSThemeAuthForm60900(themeVars),
    registration: JSSThemeRegistration60900(themeVars),
    balance: JSSThemeBalance60900(themeVars),
    casino: JSSThemeCasino60900(themeVars),
    mybets: JSSThemeMyBets60900(themeVars),
    accountSection: JSSThemeAccountSection60900(themeVars),
    live: JSSThemeLive60900(themeVars),
    cookies: JSSThemeCookies60900(themeVars),
    popularLeagues: JSSThemePopularLeagues60900(themeVars),
    xtremepush: JSSThemeXtremepush60900(themeVars),
    Heading: {
      common: {
        color: text5,
        padding: [margins.xs, margins.xm, 0],
        fontSize: 30,
        '@media (pointer: coarse)': {
          color: white,
        },
        '&.headingMobile': {
          color: white,
          background: '#171b28',
          padding: [margins.xs, margins.xm],
        },
        none: {
          paddingLeft: 0,
          paddingRight: 0,
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
    NoEventsMessage: {
      root: {
        color: `${text4} !important`,
      },
    },
    DropDownUI: {
      title: {
        '&:hover': {
          opacity: 1,
        },
      },
    },
    PageNotFound: {
      pageNotFoundRoot: {
        background: background3,
      },
      title: {
        color: white,
        '@media (pointer: coarse)': {
          fontSize: fontSizes.xxxl,
        },
      },
      desciption: {
        '@media (pointer: coarse)': {
          fontSize: fontSizes.md,
        },
      },
    },
    Button: {
      accent: {
        background: text4,
        color: white,
        '&:hover': {
          color: white,
          background: text4,
          opacity: 0.9,
        },
      },
      block: {
        '@media (pointer: coarse)': {
          fontWeight: 'bold',
        },
      },
      small: {
        padding: [margins.xs, margins.md],
      },
      large: {
        padding: [margins.md, margins.xl],
      },
      linkDefault: {
        color: black,
        '@media (pointer: coarse)': {
          //applyOnlyDesktop
          color: background5,
          '&:hover': {
            color: background5,
          },
        },
      },
      primary: {
        color: black,
        '&:hover': {
          color: black,
        },
      },
      secondary: {
        color: black,
        background: background5,
        '&:hover': {
          color: black,
          background: white,
        },
      },
    },
    Geoblock: {
      rootGeoblock: {
        background: white,
      },
      logoGeoblock: {
        background: background3,
      },
      textGeoblock: {
        color: background3,
      },
    },
    betgenius: JSSThemeBetgenius60900(themeVars),
    bonuses: JSSThemeBonuses60900(themeVars),
    tooltip: JSSThemeTooltip60900(themeVars),
    footer: JSSThemeFooter60900(themeVars),
    footerAll: JSSThemeFooterAll60900(themeVars),
    headerMobile: JSSThemeHeaderMobile60900(themeVars),
    outcomes: JSSThemeOutcomes60900(themeVars),
    promotions: JSSThemePromotions60900(themeVars),
    affiliates: JSSThemeAffiliates60900(themeVars),
    sitemap: JSSThemeSitemap60900(themeVars),
    dropdownMenu: JSSThemeDropdownMenu60900(themeVars),

    LiveNow: {
      title: {
        background: `transparent !important`,
        textTransform: 'uppercase !important',
        borderRadius: '0px !important',
        width: '100% ',
        margin: '0 !important',
        padding: '0 !important',
        color: `${black} !important`,
      },
    },
    BetslipMyBetsPanelTitle: {
      heading: {
        color: text4,
        background: white,
      },
    },
    OfflineMessage: {
      popup: {
        color: black,
      },
    },
    BetslipMyBetsPanelUI: {
      panel: {
        background: 'white',
      },
    },
    NoBetsMessage: {
      root: {
        background: white,
        fontSize: fontSizes.sm,
      },
    },
    BetslipMybetsToggler: {
      root: { background: `${background3} !important` },
      badge: { color: `${background3} !important` },
      close: { background: `${background3} !important` },
    },
    LiveCalendar: {
      common: {
        margin: 0,
      },
      dateEvent: {
        color: black,
        background: white,
        '&:hover': {
          color: black,
          background: background2,
        },
      },
      link: {
        color: black,
      },
    },
    BetslipMyBetsStakePotentialTotalUI: {
      stakePotentialWrapper: {
        borderTop: `1px solid ${accent}`,
        paddingBottom: 3,
      },
    },
  };
};

export const getJSSThemePatterns60900: GetJSSThemePatternsType =
  getJSSThemePatterns;

// @flow
import { map, sum, compose, values, isEmpty } from 'ramda';
import { createSelector } from 'reselect';
import type {
  ReduxState,
  BetslipSelectionType,
  BetslipSelectionSingleType,
  BetslipSelectionMultipleType,
  BetslipSelectionSystemType,
  BetslipReceiptType,
  BetslipSelectionMultipleWithBonusType,
} from '../../types';
import {
  BETSLIP_SELECTION_TYPES,
  getSelectionPotentialReturn,
  getSelectionTotalStake,
  getIsSelectionOpen,
  filterProcessedTransaction,
  getSelectionPotentialReturnWithBonus,
  getSelectionCurrentPrice,
} from './betslip-utils';
import { defaultRound } from '../../koltron-utils/format-and-round';

const getBetslipRoot = (state: ReduxState) => state.betslip;
const getBetslipSelections = (state: ReduxState) =>
  getBetslipRoot(state).selections;
const getReceipt = (state: ReduxState) => getBetslipRoot(state).receipt;
export const getBetslipOutcomeIds: ReduxState => Array<string> = createSelector(
  state => getBetslipRoot(state).outcomeIds,
  outcomeIds => outcomeIds.map(o => o.id)
);

export const getBetslipOutcomeIdsEmpty: ReduxState => boolean = createSelector(
  getBetslipOutcomeIds,
  isEmpty
);

const getBetslipSelectionsArray = createSelector(
  getBetslipSelections,
  values
);

const filterMultiple = selections =>
  selections.filter(
    selection => selection.type === BETSLIP_SELECTION_TYPES.multiple
  );

const filterMultipleWithBonus = selections => {
  return selections.filter(
    selection => selection.type === BETSLIP_SELECTION_TYPES.multipleWithBonus
  );
};

const filterSortSystem = selections =>
  selections
    .filter(selection => selection.type === BETSLIP_SELECTION_TYPES.system)
    .sort((a, b) => Number(a.id) - Number(b.id));

const filterSortSingles = (selections, outcomeIds) =>
  ((selections.filter(
    selection => selection.type === BETSLIP_SELECTION_TYPES.single
  ): any): Array<BetslipSelectionSingleType> | Array<BetslipReceiptType>).sort(
    (a, b) =>
      outcomeIds.indexOf(a.outcomeId.toString()) -
      outcomeIds.indexOf(b.outcomeId.toString())
  );

export const getSingles: ReduxState => Array<BetslipSelectionSingleType> = createSelector(
  getBetslipSelectionsArray,
  getBetslipOutcomeIds,
  ((filterSortSingles: any): (
    Array<BetslipSelectionType>,
    Array<string>
  ) => Array<BetslipSelectionSingleType>)
);

export const getMultiples: ReduxState => Array<BetslipSelectionMultipleType> = createSelector(
  getBetslipSelectionsArray,
  ((filterMultiple: any): (
    selections: Array<BetslipSelectionType>
  ) => Array<BetslipSelectionMultipleType>)
);

export const getMultiplesWithBonus: ReduxState => Array<BetslipSelectionMultipleWithBonusType> = createSelector(
  getBetslipSelectionsArray,
  ((filterMultipleWithBonus: any): (
    selections: Array<BetslipSelectionType>
  ) => Array<BetslipSelectionMultipleWithBonusType>)
);

export const getSystems: ReduxState => Array<BetslipSelectionSystemType> = createSelector(
  getBetslipSelectionsArray,
  ((filterSortSystem: any): (
    selections: Array<BetslipSelectionType>
  ) => Array<BetslipSelectionSystemType>)
);

export const getReceiptSingles: ReduxState => Array<BetslipReceiptType> = createSelector(
  getReceipt,
  getBetslipOutcomeIds,
  ((filterSortSingles: any): (
    Array<BetslipReceiptType>,
    Array<string>
  ) => Array<BetslipReceiptType>)
);

const getReceiptMultiples: ReduxState => Array<BetslipReceiptType> = createSelector(
  getReceipt,
  ((filterMultiple: any): (
    selections: Array<BetslipReceiptType>
  ) => Array<BetslipReceiptType>)
);

const getReceiptSystems: ReduxState => Array<BetslipReceiptType> = createSelector(
  getReceipt,
  ((filterSortSystem: any): (
    selections: Array<BetslipReceiptType>
  ) => Array<BetslipReceiptType>)
);

const filterHasTransaction = a => a.filter(v => v.transaction);

export const getReceiptSelectionsWithTransactions: ReduxState => Array<BetslipReceiptType> = createSelector(
  getReceipt,
  filterHasTransaction
);

export const getReceiptSinglesWithTransaction: ReduxState => Array<BetslipReceiptType> = createSelector(
  getReceiptSingles,
  filterHasTransaction
);

export const getReceiptMultiplesWithTransaction: ReduxState => Array<BetslipReceiptType> = createSelector(
  getReceiptMultiples,
  filterHasTransaction
);

export const getReceiptSystemsWithTransaction: ReduxState => Array<BetslipReceiptType> = createSelector(
  getReceiptSystems,
  filterHasTransaction
);

export const getSinglesCount = (state: ReduxState) => getSingles(state).length;

const filterGt0stake = arr => arr.filter(a => a.stake > 0);

const getSystemsStakeGt0: ReduxState => Array<BetslipSelectionSystemType> = createSelector(
  getSystems,
  filterGt0stake
);

const getSinglesStakeGt0: ReduxState => Array<BetslipSelectionSingleType> = createSelector(
  getSingles,
  filterGt0stake
);

const getMultiplesStakeGt0: ReduxState => Array<BetslipSelectionMultipleType> = createSelector(
  getMultiples,
  filterGt0stake
);

const getMultiplesWithBonusStakeGt0: ReduxState => Array<BetslipSelectionMultipleWithBonusType> = createSelector(
  getMultiplesWithBonus,
  filterGt0stake
);

export const getReceiptMultipleBets: ReduxState => boolean = createSelector(
  getReceipt,
  selections => selections.length > 1
);

export const getCanPlaceBet: ReduxState => boolean = createSelector(
  getBetslipSelectionsArray,
  selections => selections.some(selection => selection.stake > 0)
);

const getOpenSingles: ReduxState => Array<BetslipSelectionSingleType> = createSelector(
  getSingles,
  selections => selections.filter(getIsSelectionOpen)
);

export const getSingleIds: ReduxState => Array<string> = createSelector(
  getSingles,
  selections => selections.map(selection => selection.outcomeId)
);

export const getBetslipTotalStake: ReduxState => number = createSelector(
  getBetslipSelectionsArray,
  compose(
    sum,
    map(getSelectionTotalStake)
  )
);

export const getBetslipTotalPotentialReturn: ReduxState => number = createSelector(
  getBetslipSelectionsArray,
  compose(
    sum,
    map(getSelectionPotentialReturn)
  )
);

export const getBetslipWithBonusTotalPotentialReturn: ReduxState => number = createSelector(
  getMultiplesWithBonus,
  compose(
    sum,
    map(getSelectionPotentialReturnWithBonus)
  )
);

export const getBetslipWithBonusPrice: ReduxState => number = createSelector(
  getMultiplesWithBonus,
  compose(
    sum,
    map(getSelectionCurrentPrice)
  )
);

export const getReceiptTotalStake: ReduxState => number = createSelector(
  compose(
    filterProcessedTransaction,
    getReceiptSelectionsWithTransactions
  ),
  compose(
    sum,
    map(getSelectionTotalStake)
  )
);

export const getReceiptTotalPotentialReturn: ReduxState => number = createSelector(
  compose(
    filterProcessedTransaction,
    getReceiptSelectionsWithTransactions
  ),
  compose(
    sum,
    map(getSelectionPotentialReturn)
  )
);

export const getErrors = (state: ReduxState) => getBetslipRoot(state).errors;

export const getStatus = (state: ReduxState) => getBetslipRoot(state).status;

export const getIsCopyStakeAvailable = (state: ReduxState, id: string) => {
  const firstSingle = getOpenSingles(state)[0];
  return !!(firstSingle && firstSingle.id === id);
};

export const getIsCopyStakeActive = (state: ReduxState, id: string) =>
  getIsCopyStakeAvailable(state, id) && getOpenSingles(state).length > 1;

export const getNeedsAcceptChanges: ReduxState => boolean = createSelector(
  getBetslipSelectionsArray,
  selections =>
    selections.some(
      selection =>
        (typeof selection.priceDelta === 'number' &&
          selection.priceDelta !== 0) ||
        !getIsSelectionOpen(selection)
    )
);

type BetPlacementData = {
  outcomes: Array<{ id: string, priceId: string }>,
  singles?: Array<{
    outcomeId: string,
    stake: number,
    isEachWay: boolean,
  }>,
  multiple?: { stake: number, isEachWay: boolean },
  systems?: Array<{
    type: number,
    stake: number,
    isEachWay: boolean,
  }>,
};

const getOutcomeMetas = createSelector(
  (state: ReduxState) => state.betslip.outcomeIds,
  outcomeIds => {
    const obj = {};
    for (const { id, meta } of outcomeIds) {
      obj[id] = meta;
    }
    return obj;
  }
);

export const getBetPlacementData: ReduxState => BetPlacementData = createSelector(
  getSingles,
  getSinglesStakeGt0,
  getMultiplesStakeGt0,
  getSystemsStakeGt0,
  getOutcomeMetas,
  (outcomes, singles, multiples, systems, metas) => {
    const data: BetPlacementData = {
      outcomes: outcomes.map(({ id, priceId }) => ({
        id,
        priceId,
        meta: JSON.stringify(metas[id]),
      })),
    };

    if (singles.length > 0) {
      data.singles = singles.map(({ outcomeId, stake, isEachWay }) => ({
        outcomeId,
        stake: defaultRound(stake),
        isEachWay,
      }));
    }

    if (multiples.length > 0) {
      data.multiple = {
        stake: defaultRound(multiples[0].stake),
        isEachWay: multiples[0].isEachWay,
      };
    }

    if (systems.length > 0) {
      data.systems = systems.map(({ minWinSelections, stake, isEachWay }) => ({
        type: minWinSelections,
        stake: defaultRound(stake),
        isEachWay,
      }));
    }

    return data;
  }
);

export const getBetPlacementDataWithBonus: ReduxState => BetPlacementData = createSelector(
  getSingles,
  getMultiplesWithBonusStakeGt0,
  getOutcomeMetas,
  (outcomes, multipleWithBonus, metas) => {
    const data: BetPlacementData = {
      outcomes: outcomes.map(({ id, priceId }) => ({
        id,
        priceId,
        meta: JSON.stringify(metas[id]),
      })),
    };

    if (multipleWithBonus.length > 0) {
      if (outcomes.length === 1) {
        data.singles = outcomes.map(({ id, isEachWay }) => ({
          outcomeId: id,
          stake: defaultRound(multipleWithBonus[0].stake),
          isEachWay,
        }));
      } else {
        data.multiple = {
          stake: defaultRound(multipleWithBonus[0].stake),
          isEachWay: multipleWithBonus[0].isEachWay,
        };
      }
    }

    return data;
  }
);

// @flow
import { type JSSThemeDefaultCasinoType } from '../JSSThemeDefaultCasino';
import { applyOnlyMobile, applyOnlyDesktop } from '../jss-helpers';

export const JSSThemeCasino60900: JSSThemeDefaultCasinoType = themeVars => {
  const {
    margins,
    colors: {
      colorTypes: {
        white,
        neutral1,
        text4,
        text5,
        primary,
        inform,
        background2,
        black,
      },
      colorCombinations: { whitePrimary },
    },
    fontSizes,
  } = themeVars;
  return {
    CasinoGameCategories: {
      gameCategories: {
        background: text5,
      },
      promosCasino: {
        display: 'flex',
        color: white,
        borderRight: 'none',
        '&.active, &:hover': {
          ...whitePrimary,
        },
      },
      menuItem: {
        color: white,
        borderRight: 'none',
        '&.active, &:hover': {
          background: primary,
          color: black,
        },
        ...applyOnlyDesktop({
          '&:hover': {
            ...whitePrimary,
          },
        }),
        ...applyOnlyMobile({
          '&:hover, &:active': {
            color: white,
          },
        }),
      },
      iconChevron: {
        marginLeft: margins.xs,
        '&.active': {
          transform: 'rotateZ(90deg) translateX(1px) translateY(-2px)',
        },
      },
    },
    SearchBox: {
      searchResultHeading: {
        color: black,
      },
      inputWrap: {
        display: 'flex',
        alignItems: 'center',
      },
      inputSearch: {
        height: 32,
        borderBottom: '1px solid rgba(250,250,250,0.3)',
        '&::placeholder': {
          opacity: 0.5,
        },
      },
      beginTyping: {
        color: 'black',
      },
      searchResult: {
        background: neutral1,
        padding: [0, margins.md],
      },
    },
    CasinoContainer: {
      casinoRoot: {
        background: text5,
      },
      providersFiltersWrap: {
        background: primary,
      },
      providersFilters: {
        opacity: 1,
        '&.active': {
          background: text4,
        },
        ...applyOnlyDesktop({
          '&:hover': {
            background: text4,
          },
        }),
      },
    },
    CasinoGameLink: {
      rootReal: {
        background: primary,
        fontSize: fontSizes.md,
        '&:hover': {
          color: 'white',
          background: inform,
        },
      },
      rootFun: {
        fontSize: fontSizes.md,
        background: 'white',
        color: 'black',
        '&:hover': {
          background: background2,
          color: 'black',
        },
      },
    },
  };
};
